import React from "react"
import Layout from "../components/layout/layout"
import SEOComponent from "../components/seo"
import AmberScore from "../components/reusable/AmberScore";

const DataProtection = () => (
    <Layout>
        <SEOComponent title="Amber by inFeedo | Data Protection" />
        <div class="content has-text-centered columns flex-wrap mar-top-160">
            <h2 class="text-block-17 column is-12 is-paddingless">Data Protection Addendum</h2>
            <div className="font-15 pd-40 column is-9 mar-auto has-text-justified">
                <p><a target="_blank" rel="noopener noreferrer" href="../assets/Data_Protection_Addendum.pdf" className="anchor-hov-blue">Download our Data Protection Addendum here.</a></p>
                <p>If you have any questions or would like a signed copy of the DPA, you may contact us at: <a href="mailto:privacy@infeedo.com">privacy@infeedo.com</a><br />
                    <br /><b>DPA was last updated on October 18, 2021</b></p>
            </div>
        </div>
        <AmberScore />
    </Layout>
)

export default DataProtection